class Lightbox {

    constructor() {
        this.lightboxTrigger = document.querySelectorAll(".js-lightbox");
        this.lightbox = document.querySelector(".c-lightbox");
        this.lightboxContent = document.querySelector(".js-lightboxContent");
        this.lightboxClose = document.querySelector(".js-lightbox__close");
    }

    /* eslint-disable */
    init() {
        this.events()
    }

    events() {
        this.lightboxTrigger.forEach( (lightboxItem) => {
            lightboxItem.addEventListener('click', (ev) => {                
                let lbData = lightboxItem.dataset.lightbox
                let lbSrc = lbData.split('src=')[1]
                let lbUrl = lbSrc.split('"')[1]
                let lbParams = lbUrl.split('/')[4]
                let videoId = lbParams.split('?')[0]

                this.openLightBox(this.lightbox)

                this.onYouTubeIframeAPIReady(this.lightboxContent, videoId)
            })
        })
    }

    openLightBox(el) {
        if (!el.classList.contains('open')) {
            el.classList.add('open');
        }
    }

    closeLightBox(player) {
        this.lightboxClose.addEventListener('click', (ev) => {
            if (this.lightbox.classList.contains('open')) {
                this.lightbox.classList.remove('open');
            }

            player.pauseVideo();
        })
    }

    onYouTubeIframeAPIReady(player, id) {
        this.player = new YT.Player(player, {
          height: '390',
          width: '640',
          videoId: id,
          events: {
            'onReady': this.onPlayerReady
          }
        });

        this.closeLightBox(this.player)
    }
      
    onPlayerReady(event) {
        event.target.playVideo();
    }
    /* eslint-enable */
}

export default Lightbox
