class ScrollAnchor {

    constructor() {  
        this.btn = document.querySelector('.js-anchor-trig')
        this.anchor = document.querySelector('.js-anchor')
    }
  
    init() {
        if (this.btn || this.anchor) {
            this.events()
        }      
    }
  
    events() {

        this.btn.addEventListener( 'click', () => {
            this.trigger()
        })
    }

    /* eslint-disable */
    trigger () {
        this.anchor.scrollIntoView({
            behavior: "smooth"
        })
    }
    /* eslint-enable */

}

export default ScrollAnchor