class MegaNav {

    constructor() {
        this.megaNavTrigger = document.querySelectorAll('.js-mega-nav-trig')
        this.megaNavTriggerAria = document.querySelectorAll('.js-mega-nav-trig-aria')
        this.megaNavItem = document.querySelectorAll('.c-mega-nav')
        this.mobileSubOpen = document.querySelectorAll('.js-open-sub')
        
        this.megaContentListPrime = document.querySelector('.c-mega-nav__primary')
        this.megaContentList = document.querySelectorAll('.c-mega-nav__secondary')
        this.megaContentTarget = document.querySelectorAll('.c-mega-nav__inner')
        this.contentListArray = []
    }

    init() {
        this.events()
        this.getMegaHeight()
    }

    events() {
        
        this.megaNavTrigger.forEach( ( navItem ) => {
            
            this.eventListeners( navItem )

        })

        this.megaNavTriggerAria.forEach( ( navItem ) => {
            
            this.ariaListeners( navItem )

        })

        this.megaNavItem.forEach( ( navItem ) => {            
            
            this.eventListeners( navItem )

        })

        this.mobileSubOpen.forEach( (item)  => {

            item.addEventListener('click', () => {

                console.log('Howya')
            
                item.nextElementSibling.classList.add('open')
                
            })
        })
    }

    /* eslint-disable */
    eventListeners( navItem, level = 'top') {

        if ( window.innerWidth < 1200) {

            navItem.addEventListener( "click", (e) => {

                e.stopPropagation()

                const activeTrig = this.matchNav( navItem, level )
                if ( !navItem.classList.contains('c-mega-nav') ) {   
                    this.triggerNav('add', activeTrig, level, 'mobile')
                }
            })

        } else {

            navItem.addEventListener( "mouseover", () => {
                this.elFuncs( navItem, level, 'add')
            })

            navItem.addEventListener( "mouseout", () => {
                this.elFuncs( navItem, level, 'remove')
            })
        }

    }

    ariaListeners( navItem, level = 'top' ) {
        if ( window.innerWidth < 1200) {

            // navItem.addEventListener( "click", (e) => {

            //     const activeTrig = this.matchNav( navItem, level )
            //     this.triggerNav('add', activeTrig, level, 'mobile')
            // })

        } else {

            navItem.addEventListener( "keydown", (e) => {
                if (e.keyCode == 13) {
                    this.triggerNavAria( navItem.dataset.index, 'add')
                }
            })

            navItem.addEventListener( "keydown", (e) => {
                if (e.keyCode == 27) {
                    this.triggerNavAria( navItem.dataset.index, 'remove')
                }
            })
        }
    }

    elFuncs( navItem, level, type ) {

        const activeTrig = this.matchNav( navItem, level )
        this.triggerNav(type, activeTrig, level)

    }

    matchNav( el, level ) {
        if ( level === 'sub' ) {
            return el.dataset.megaSub
        } else {
            return el.dataset.megaIndex
        }
    }

    triggerNav ( action, i, level = 'top', aspect = 'desktop' ) {
        
        let megaDiv = document.querySelector('.mega-'+i)

        if ( aspect == 'desktop' ) {
            if ( action == 'add' ) {
                this.openNav(megaDiv, i)
            } else {
                this.closeNav(megaDiv, i)
            }
        } else {
            if ( action == 'add' ) {
                this.openNav(megaDiv, i)
            }
        }
    }

    triggerNavAria(i, action) {
        
        let megaDiv = document.querySelector('.mega-'+i)

        if ( action == 'add' ) {
            this.openNav(megaDiv, i)
        } else {
            this.closeNav(megaDiv, i)
        }
    }
    
    openNav( megaDiv, i ) {
        this.megaNavTrigger[i].classList.add('open')
        console.log('Open')
        if (megaDiv) {
            megaDiv.classList.add('open')
            megaDiv.setAttribute( 'aria-hidden', false)
            megaDiv.focus();
        }
    }

    closeNav( megaDiv, i ) {
        this.megaNavTrigger[i].classList.remove('open')
        console.log('Close')
        if (megaDiv) {
            megaDiv.classList.remove('open')
            megaDiv.setAttribute( 'aria-hidden', true)
        }
    }

    getMegaHeight () {
        this.megaContentList.forEach( (item) => (
            
            this.contentListArray.push(item.clientHeight)
            
        ))

        setTimeout( () => {
            this.setMegaHeight();
        }, 100)
    }

    setMegaHeight () {
        
        const primeHeight = this.megaContentListPrime.clientHeight
        const secHeight = Math.max(...this.contentListArray)

        if ( secHeight > primeHeight ) {

            this.megaContentTarget.forEach( ( item ) => (
                item.style.height = secHeight+'px'
            ))

        } else {

            this.megaContentTarget.forEach( ( item ) => (
                item.style.height = primeHeight+'px'
            ))

        }

        this.megaContentList.forEach( (item) => (
            setTimeout( () => {
                item.classList.add('c-mega-nav__secondary--zero-height')
            }, 150)
        ))
    }


    /* eslint-enable */
    
}

export default MegaNav