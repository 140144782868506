class PostFilter {

    constructor() {
      this.category = document.querySelector('.js-filter-cat')
      this.order = document.querySelector('.js-filter-order')
    }
  
    init() {
        if ( this.category || this.order ) {
            this.setSelect()
            this.filterChange()
        }
    }
  
    filterChange () {

        if ( this.category || this.order) {

            this.category.addEventListener('change', () => {
                    
                this.createQuery(this.getCat(), this.getOrder()) 

            })

            this.order.addEventListener('change', () => {
                    
                this.createQuery(this.getCat(), this.getOrder()) 

            })

        }

    }

    /* eslint-disable */
    setSelect () {

        var url = new URL(document.location)
        var params = url.searchParams
        // var currentCategory = params.get("cat")
        var currentCategory = url.pathname.split('/')[1]
        var currentOrder = params.get("order")
        
        if ( currentCategory != 'blog' ) {
            this.category.value = currentCategory
        } else {
            this.category.value = 'blog'
        }

        if ( currentOrder ) {
            this.order.value = currentOrder
        }
      
    }

    getCat () {
        return this.category.value
    }

    getOrder () {
        return this.order.value
    }
    
    
    createQuery ( category, order ) {
       
        const url = new URL(window.location.href)
        const origin = url.origin

        // const page = url.pathname.split('/')[1]
        const search_params = url.searchParams

        // search_params.set('cat', category)
        search_params.set('order', order)
        // const path = origin + '/' + page

        const path = origin+'/'+category
        url.search = search_params.toString()
        
        let updatedUrl = ''
        
        if ( order == 'oldest' ) {
            updatedUrl = path + url.search
        } else {
            updatedUrl = path
        }

        window.location.href = updatedUrl
    }

    /* eslint-enable */
    
  }
  
  export default PostFilter
  