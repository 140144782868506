class Testimonials {

  constructor() {
    this.testimonials = document.querySelectorAll('.c-testimonials__swiper')
    this.testimonialsPrev = document.querySelectorAll('.c-testimonials__prev')
    this.testimonialsNext = document.querySelectorAll('.c-testimonials__next')
  }

  init() {
    this.setupSwiper()
  }

  /* eslint-disable */
  setupSwiper () {
    this.testimonials.forEach( (testimonials, i) => {

        testimonials.classList.add('c-testimonials--'+i)
        this.testimonialsPrev[i].classList.add('c-testimonials__prev--'+i)
        this.testimonialsNext[i].classList.add('c-testimonials__next--'+i)
        
        let slider = new Swiper( '.c-testimonials--'+i, {
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.c-testimonials__next--'+i,
                prevEl: '.c-testimonials__prev--'+i,
            },
            pagination: {
                el: '.c-testimonials--'+i+' .c-testimonials__pagination',
                clickable: true,
                type: 'bullets'
            }
        } );

    } );
  }
  /* eslint-enable */
  
}

export default Testimonials
