class Video {

  constructor() {
    this.tag = document.createElement("script");
    this.player = '';
    this.playeriFrame = document.querySelector('.js-video')
    this.placeholder = document.querySelector('.js-video-placeholder')
    if ( this.playeriFrame ) {
      this.videoId = this.playeriFrame.dataset.video
    }
  }

  /* eslint-disable */
  init() {
    this.tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(this.tag, firstScriptTag);

    this.tag.onload = () => {
      this.initYoutube()
    }
  }

  initYoutube () {
    var self = this
    if ( self.playeriFrame ) {
      window.YT.ready(() => {
        new window.YT.Player(this.playeriFrame, {
            height: "360",
            width: "640",
            videoId: self.videoId,
            events: {
                'onReady': ( ev ) => {
                  self.onPlayerReady( ev, self )
                },
                'onStateChange': ( ev ) => {
                  self.onPlayerStateChange( ev, self )
                }
            }
        })
      })
    }
  }

  onPlayerReady( ev, self ) {
    self.placeholder.addEventListener('click', () => {
        ev.target.playVideo()
    })
}

  onPlayerStateChange( ev, self ) {
    if ( ev.data == 1 ) {
        self.placeholder.classList.add('hide')
    } else {
        self.placeholder.classList.remove('hide')
    }
  }
  /* eslint-enable */
}

export default Video
