import Header from './classes/Header.js';
import Search from './classes/Search.js';
import MegaNav from './classes/MegaNav.js';
import MegaNavSub from './classes/MegaNavSub.js';
import Carousel from './classes/Carousel.js';
import Gallery from './classes/Gallery.js';
import Testimonials from './classes/Testimonials.js';
import Accordion from './classes/Accordion.js';
import PostFilter from './classes/PostFilter.js';
import ScrollAnchor from './classes/ScrollAnchor.js';
import Video from './classes/Video.js';
import Lightbox from './classes/Lightbox.js';
import Map from './classes/Maps.js';
import AnimateStatNumbers from './classes/AnimateStatNumbers.js'
import Init from './classes/Init';

const siteFunctions = {
  documentReady__ready() {
  },

  header__ready() {
    const header = new Header();
    header.init();
  },

  search__ready() {
    const search = new Search();
    search.init();
  },

  megaNav__ready() {
    const megaNav = new MegaNav();
    megaNav.init();
  },

  megaNavSub__ready() {
    const megaNavSub = new MegaNavSub();
    megaNavSub.init();
  },

  carousel__ready() {
    const carousel = new Carousel();
    carousel.init();
  },

  gallery__ready() {
    const gallery = new Gallery();
    gallery.init();
  },

  testimonials__ready() {
    const testimonials = new Testimonials();
    testimonials.init();
  },

  accordion__ready() {
    const accordion = new Accordion();
    accordion.init();
  },

  postFilter__ready() {
    const postFilter = new PostFilter();
    postFilter.init();
  },

  scrollAnchor__ready() {
    const scrollAnchor = new ScrollAnchor();
    scrollAnchor.init();
  },

  video__ready() {
    const video = new Video();
    video.init();
  },

  lightbox__ready() {
    const lightbox = new Lightbox();
    lightbox.init();
  },

  map__ready() {
    const map = new Map();
    map.init();
  },

  animateStatNumbers_ready() {
    const animateStatNumbers = new AnimateStatNumbers();
    animateStatNumbers.init();
  }
};

window.functionCore = new Init(siteFunctions);
