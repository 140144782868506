class Map {

    constructor() {
        this.map = document.querySelector('.c-map__map');
        this.mapTag = document.createElement('script');
    }
    
    /* eslint-disable */
    init() {
        // AD Maps API AIzaSyAs0OgkdSVZhOnao8SZPsy7O_lMSaiU5oU
        this.mapTag.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBcVxW_JXjBQ8Em7nA6RgvC4LTrK4vAlts&callback=initMap';
        this.mapTag.async = true;

        document.head.appendChild(this.mapTag);
        
        if ( this.map ) {
            window.initMap = this.initMap;
        } else {
            window.initMap = this.fallback;
        }
    }

    initMap() {
        let map;
        const getMarkers = document.querySelector('.c-map__map');
        const markerArr = JSON.parse(getMarkers.dataset.markers)

        map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 54.793447, lng: -4.894711 },
            zoom: 6,
            disableDefaultUI: true,
            mapTypeId: 'terrain'
        });

        for (let i = 0; i < markerArr.length; i++) {
            const marker = markerArr[i]
            new google.maps.Marker({
                position: { lat: marker[0], lng: marker[1] },
                map
            })
        }
    }

    fallback() {
        return false
    }

    /* eslint-enable */
}

export default Map