class Header {

  constructor() {
    this.body = document.querySelector('body')
    this.header = document.querySelector('.c-header')
    this.isHome = document.querySelector('.js-home')
    this.nav = document.querySelector('.js-primary-nav')
    this.burger = document.querySelector('.js-burger')
    this.closeBurger = document.querySelectorAll('.js-burger-close')
    // this.closeBurger = document.querySelector('.js-burger-close')

    // Mobile nav closing classes
    this.megaNavItem = document.querySelectorAll('.c-mega-nav')
    this.megaSubItem = document.querySelectorAll('.mega-sub')
    this.navSub = document.querySelectorAll('.c-navigation__children')
    
    this.closeLevelOne = document.querySelectorAll('.js-level-one-close')
    this.closeLevelTwo = document.querySelectorAll('.js-level-two-close')
    
  }

  init() {
    this.events()
  }

  events() {

    this.burger.addEventListener('click', () => {
      this.toggle()
    });


    this.closeBurger.forEach( ( closeItem ) => (
      
      closeItem.addEventListener('click', () => {
        this.toggle(closeItem)

        this.closeMega()

        this.closeMegaSub()

        this.closeNavSub()

      })

    ))

    this.closeLevelOne.forEach( ( item ) => {
      item.addEventListener( 'click', () => {
        
        this.closeMega()

        this.closeNavSub()

      })
    })

    this.closeLevelTwo.forEach( ( item ) => {
      item.addEventListener( 'click', () => {
        
        this.closeMegaSub()
        
      })
    })

    this.navShrink( this.header, this.isHome )

  }

  toggle (elem) {
    if ( this.burger.classList.contains('open') ) {
      this.close(this.burger)
      this.close(this.nav)
      this.close(elem)
    } else {
      this.open(this.burger)
      this.open(this.nav)
    }
  }

  closeMega () {
    this.megaNavItem.forEach( ( item ) => {

      if ( item.classList.contains('open') ) {
        item.classList.remove('open')
      }
    })
  }

  closeMegaSub () {
    this.megaSubItem.forEach( ( item ) => {

      if ( item.classList.contains('open') ) {
        item.classList.remove('open')
      }
    })
  }

  closeNavSub () {
    this.navSub.forEach( ( item ) => {

      if ( item.classList.contains('open') ) {
        item.classList.remove('open')
      }
    })
  }

  /* eslint-disable */
  open (elem) {
    elem.classList.add('open')
    this.body.classList.add('no-scroll')
  }

  close (elem) {
    elem.classList.remove('open')
    this.body.classList.remove('no-scroll')
  }

  navShrink ( elem, home ) {

      window.addEventListener('scroll', function ( event ) {

          let fromTop = window.scrollY
          
          if ( fromTop > 250 ) {
            elem.classList.add('c-header--shrink')
            if ( !home ) {
              elem.classList.add('c-header--white')
            }
          } else {

            elem.classList.remove('c-header--shrink')

            if ( !home ) {
              elem.classList.remove('c-header--white')
            }
          }


      })
  }

  /* eslint-enable */
}

export default Header
