class Search {

    constructor() {
      this.form = document.querySelector('.js-search-form')
      this.toggleSearch = document.querySelector('.js-search-toggle')
      this.closeSearch = document.querySelector('.js-search-close')
    }
  
    init() {
      this.events()
    }
  
    events() {
  
      this.toggleSearch.addEventListener('click', () => {
        this.toggle()
      });
  
      this.closeSearch.addEventListener('click', () => {
        this.toggle()
      });
  
    }
  
    toggle () {
      if ( this.form.classList.contains('e-search__form--open') ) {
        this.close(this.form)
      } else {
        this.open(this.form)
      }
    }
  
    /* eslint-disable */
    open (elem) {
      elem.classList.add('e-search__form--open')
    }
  
    close (elem) {
      elem.classList.remove('e-search__form--open')
    }
    /* eslint-enable */
  }
  
  export default Search
  