class Carousel {

  constructor() {
    this.carousel = document.querySelectorAll('.c-carousel__swiper')
    this.carouselPrev = document.querySelectorAll('.c-carousel__prev')
    this.carouselNext = document.querySelectorAll('.c-carousel__next')
  }

  init() {
    this.setupSwiper()
  }

  /* eslint-disable */
  setupSwiper () {
    this.carousel.forEach( (carousel, i) => {

        carousel.classList.add('c-carousel--'+i)
        this.carouselPrev[i].classList.add('c-carousel__prev--'+i)
        this.carouselNext[i].classList.add('c-carousel__next--'+i)
        
        let slider = new Swiper( '.c-carousel--'+i, {
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.c-carousel__next--'+i,
                prevEl: '.c-carousel__prev--'+i,
            },
            pagination: {
                el: '.c-carousel--'+i+' .c-carousel__pagination',
                clickable: true,
                type: 'bullets'
            }
        } );

    } );
  }
  /* eslint-enable */
  
}

export default Carousel
