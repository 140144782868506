class Accordion {

    constructor() {  
      this.accordions = document.querySelectorAll('.js-accordion')
    }
  
    init() {
      this.events()
    }
  
    events() {
        this.accordions.forEach( ( accordion ) => {

            accordion.querySelectorAll('.js-accordion-toggle').forEach( ( accordionItem ) => {

                accordionItem.addEventListener( 'click', ( ev ) => {
                    this.trigger( ev.target )
                } )

            } )

        } )
    }

    /* eslint-disable */
    trigger ( el ) {
        const parent = el.closest('.c-accordion__item')
        
        if (parent.classList.contains('c-accordion__item--active')) {
            parent.classList.remove('c-accordion__item--active')
        } else {
            parent.classList.add('c-accordion__item--active')
        }
    }
    /* eslint-enable */

}

export default Accordion