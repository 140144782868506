class MegaNavSub {

    constructor() {

        this.megaSubTrig = document.querySelectorAll('.js-mega-sub-trig')
        this.megaSubItem = document.querySelectorAll('.mega-sub')

    }

    init() {
        this.events()
        this.status()
    }

    events() {

        this.megaSubTrig.forEach( ( navItem ) => {            
            
            this.eventListeners( navItem, 'sub' )

        })
    }

    /* eslint-disable */
    status() {
        
        if ( window.innerWidth > 1200) {

            this.megaSubTrig[0].classList.add('current')
            this.megaSubItem[0].classList.add('current')
            
        }

    }

    eventListeners( navItem, level = 'top') {

        if ( window.innerWidth < 1200) {

            navItem.addEventListener( "click", () => {
                this.elFuncs( navItem, level, 'add')
            })

        } else {

            navItem.addEventListener( "mouseover", () => {
                this.elFuncs( navItem, level, 'add')
                this.removeCurrent( navItem, this.megaSubTrig )
            })
    
            navItem.addEventListener( "mouseout", () => {
                this.elFuncs( navItem, level, 'remove')
            })
        }

    }

    elFuncs( navItem, level, type ) {

        const activeTrig = this.matchNav( navItem, level )
        this.triggerNav(type, activeTrig, level)

    }

    matchNav( el, level ) {
        if ( level === 'sub' ) {
            return el.dataset.megaSub
        } else {
            return el.dataset.megaIndex
        }
    }

    triggerNav ( action, i, level = 'top' ) {
        
            let megaDiv = document.querySelector('.mega-sub-'+i)

            this.removeCurrent( megaDiv, this.megaSubItem )

        if ( action == 'add' ) {
            this.openNav(megaDiv, i)
        } else {
            this.closeNav(megaDiv, i)
        }
    }
    
    openNav( megaDiv, i ) {

        if (megaDiv) {
            megaDiv.classList.add('open')
        }
    }

    closeNav( megaDiv, i ) {

        this.megaSubTrig[i].classList.remove('open')
        if (megaDiv) {
            megaDiv.classList.remove('open')
        }
    }

    removeCurrent(navItem, itemsList) {
        var itemsProcessed = 0;

        itemsList.forEach( (item, index, array ) => {

            item.classList.remove('current')
            itemsProcessed++;

            if(itemsProcessed === array.length) {
                this.makeCurrent(navItem);
            }

        })

    }

    makeCurrent( current ) {
        current.classList.add('current')
    }
    /* eslint-enable */
    
}

export default MegaNavSub