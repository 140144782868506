class Gallery {

    constructor() {
      this.gallery = document.querySelectorAll('.c-gallery__swiper')
      this.galleryThumbs = document.querySelectorAll('.c-gallery-thumbs__swiper')
      this.galleryPrev = document.querySelectorAll('.c-gallery__prev')
      this.galleryNext = document.querySelectorAll('.c-gallery__next')
    }
  
    init() {
      this.setupSwiper()
    }
  
    /* eslint-disable */
    setupSwiper () {
        let thumbs = []
        let galleries = []

        this.galleryThumbs.forEach( (gallery, i) => {
  
            gallery.classList.add('c-gallery-thumbs--'+i)
            
            thumbs[i] = new Swiper( '.c-gallery-thumbs--'+i, {
                spaceBetween: 32,
                slidesPerView: 5,
                freeMode: true,
                watchSlidesProgress: true
            } );
    
        } );

        this.gallery.forEach( (gallery, i) => {
    
            gallery.classList.add('c-gallery--'+i)
            this.galleryPrev[i].classList.add('c-gallery__prev--'+i)
            this.galleryNext[i].classList.add('c-gallery__next--'+i)
            
            galleries[i] = new Swiper( '.c-gallery--'+i, {
                slidesPerView: 1,
                pagination: {
                    el: '.c-gallery--'+i+' .c-gallery__pagination',
                    clickable: true,
                    type: 'bullets'
                },
                thumbs: {
                    swiper: thumbs[i],
                },
                breakpoints: {
                    1024: {
                        pagination: false,
                        navigation: {
                            nextEl: '.c-gallery__next--'+i,
                            prevEl: '.c-gallery__prev--'+i,
                        }
                    }
                }
            } );
    
        } );
    }
    /* eslint-enable */
    
  }
  
  export default Gallery
  