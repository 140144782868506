import NumberRush from '../helpers/NumberRush.js'

class AnimateStatNumbers {

    constructor() {
        this.numbers = document.querySelectorAll('.js-stat')
    }

    init() {
        this.activateAnimation()
    }

    /* eslint-disable */
    prepRush(number, i) {

        let child = number.firstElementChild
        let numberValue = child.dataset.maxNumber
        let numberSpeed = child.dataset.rushSpeed
        let numberSteps = child.dataset.rushSteps
        
        let args = {}

        if( numberSpeed ) {
            args['speed'] = parseFloat(numberSpeed)
        }

        if( numberSteps ) {
            args['steps'] = parseFloat(numberSteps)
        }
        
        if( numberSpeed ) {
            new NumberRush( 'statAni-'+i, args)
        } else {
            child.innerHTML = numberValue
        }

    }

    activateAnimation() {
        let options = {
            rootMargin: '-20px',
            threshold: 1
        }

        this.numbers.forEach( ( number, i ) => {
            
            const observer = new IntersectionObserver( (entries) => {
                if (entries[0].isIntersecting === true) {
                    this.prepRush(number, i);
                    observer.unobserve(entries[0].target)                
                }
            }, options )

            observer.observe(number)

        })
    }

}

export default AnimateStatNumbers